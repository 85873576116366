import React, {ElementType} from "react";
import classes from "classnames";

interface TitleProps extends PrimitiveProps {
    color?: "black" | "white";
    justify?: "left" | "center";
    justifyMobile?: "left" | "center";
    level: 1 | 2 | 3 | 4;
    size: "extra-small" | "small" | "medium" | "large" | "extra-large";
    whiteSpace?: "nowrap" | "normal";
}

export default function Title({
    children,
    className,
    color,
    "data-cy": dataCy,
    justify = "left",
    justifyMobile,
    level,
    size,
    whiteSpace = "normal",
}: TitleProps) {
    const Tag: ElementType = `h${level}`;
    return (
        <Tag
            className={classes(
                "ng-title",
                `ng-justify-${justify}`,
                `ng-size-${size}`,
                {
                    [`ng-color-${color}`]: color,
                    [`ng-justify-mobile-${justifyMobile}`]: justifyMobile,
                },
                `ng-whitespace-${whiteSpace}`,
                className,
            )}
            data-cy={dataCy}
        >
            {children}
        </Tag>
    );
}
