import React from "react";
import {toHtml, icon, IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {faPlus as backgroundIcon} from "@fortawesome/free-solid-svg-icons";

import Title from "../primitives/Title";
import SeafoodPlatterAdviceItem from "./SeafoodPlatterAdvice";

const BACKGROUND_ICON_COLOR = "#404040";

const getIconUrl = (faIcon: IconDefinition, color: string) => {
    const abstract = icon(faIcon).abstract[0];
    if (abstract.children) abstract.children[0].attributes.fill = color;
    return `url(data:image/svg+xml;base64,${btoa(toHtml(abstract))})`;
};

type SeafoodPlatterAdvicesProps = {
    guestsNumber: number;
};

export default function SeafoodPlatterAdvices({guestsNumber}: SeafoodPlatterAdvicesProps) {
    const style = {backgroundImage: getIconUrl(backgroundIcon, BACKGROUND_ICON_COLOR)};

    return (
        <>
            <Title justify="center" level={3} size="small">
                Notre recommandation
            </Title>
            <div className="ng-seafood-platter-advices" style={style}>
                <SeafoodPlatterAdviceItem guestsNumber={guestsNumber} seafoodType="smokedFish" />
                <SeafoodPlatterAdviceItem guestsNumber={guestsNumber} seafoodType="crustacean" />
                <SeafoodPlatterAdviceItem guestsNumber={guestsNumber} seafoodType="shell" />
                <SeafoodPlatterAdviceItem guestsNumber={guestsNumber} seafoodType="oyster" />
            </div>
        </>
    );
}
