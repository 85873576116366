import React, {Dispatch, SetStateAction} from "react";
import classes from "classnames";

import Paragraph from "../primitives/Paragraph";
import Slider from "../form/slider/Slider";
import Stack from "../primitives/Stack";
import Suite from "../primitives/Suite";
import Title from "../primitives/Title";

const GUESTS_NUMBER_MIN = 2;
const GUESTS_NUMBER_MAX = 12;
const GUESTS_NUMBER_STEP = 1;

type SeafoodPlatterSliderProps = {
    format: "standard" | "card";
    value: number[];
    setValue: Dispatch<SetStateAction<number[]>>;
};

export default function SeafoodPlatterSlider({format, value, setValue}: SeafoodPlatterSliderProps) {
    const justify = format === "card" ? "center" : "space-between";

    return (
        <>
            <Title level={3} size="small">
                <Suite
                    as="span"
                    className={classes("ng-seafood-platter-slider-title", `ng-format-${format}`)}
                    spacing="large"
                    justify={justify}
                    justifyMobile="center"
                    verticalSpacing="none"
                >
                    <span>Combien serez-vous à table ?</span>
                    <span>{value}&nbsp;personnes</span>
                </Suite>
            </Title>
            <Stack gap="none">
                <Slider
                    defaultValue={value}
                    onValueChange={setValue}
                    min={GUESTS_NUMBER_MIN}
                    max={GUESTS_NUMBER_MAX}
                    step={GUESTS_NUMBER_STEP}
                />
                <Suite justify="space-between">
                    <Paragraph>Petit comité</Paragraph>
                    <Paragraph>Grande tablée</Paragraph>
                </Suite>
            </Stack>
        </>
    );
}
