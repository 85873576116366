import React, {ReactNode} from "react";

import Suite from "@ng-components/primitives/Suite";

interface ActionsProps {
    children: ReactNode;
    className?: string;
    justify: "left" | "center";
    justifyMobile?: "left" | "center";
}

export default function Actions({children, className, justify = "center", justifyMobile}: ActionsProps) {
    return (
        <Suite className={className} justify={justify} justifyMobile={justifyMobile} spacing="medium">
            {children}
        </Suite>
    );
}
