import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

type InputAdornmentProps = {
    adornment: string | IconDefinition;
};

export function InputAdornment({adornment}: InputAdornmentProps) {
    if (typeof adornment === "string") {
        return <span className="ng-input-adornment">{adornment}</span>;
    }

    return <FontAwesomeIcon icon={adornment} className="ng-input-adornment" />;
}
