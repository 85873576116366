"use client";
import React, {useState} from "react";
import classes from "classnames";

import Stack from "../primitives/Stack";
import Suite from "../primitives/Suite";
import SeafoodPlatterAdvices from "./SeafoodPlatterAdvices";
import SeafoodPlatterSlider from "./SeafoodPlatterSlider";
import Paragraph from "../primitives/Paragraph";
import Actions from "../actions/Actions";
import Button from "../actions/Button";
import BlockTitle from "../block/BlockTitle";
import Title from "../primitives/Title";

const GUESTS_NUMBER_DEFAULT = 4;

type SeafoodPlatterCalculatorProps = {
    action?: {
        text: string;
        url: string;
    };
    format?: "standard" | "card";
};

export default function SeafoodPlatterCalculator(props?: SeafoodPlatterCalculatorProps) {
    const {action, format = "standard"} = props || {};
    const [sliderValues, setSliderValues] = useState([GUESTS_NUMBER_DEFAULT]);
    const justify = format === "card" ? "center" : "left";
    const paddingSize = format === "card" ? "small" : "large";

    return (
        <Suite
            verticalAlign="top"
            wrap="mobile"
            backgroundColor="turquoise"
            paddingTop={paddingSize}
            paddingRight={paddingSize}
            paddingBottom={paddingSize}
            paddingLeft={paddingSize}
            rounded="medium"
            justify="space-between"
            className={classes("ng-seafood-platter", `ng-format-${format}`)}
        >
            <Stack className={classes("ng-seafood-platter-block-1", `ng-format-${format}`)}>
                {format === "standard" && (
                    <BlockTitle justify={justify} justifyMobile="center">
                        Calculette de plateau de fruits de mer
                    </BlockTitle>
                )}
                {format === "card" && (
                    <Title justify={justify} justifyMobile="center" size="medium" level={2}>
                        Calculette de plateau de fruits de mer
                    </Title>
                )}
                <Paragraph justify={justify} justifyMobile="center" size="large">
                    Informations indicatives pour vous aider à dimensionner votre plateau de fruits de mer.
                </Paragraph>
                <SeafoodPlatterSlider format={format} setValue={setSliderValues} value={sliderValues} />
            </Stack>
            <Stack className={classes("ng-seafood-platter-block-2", `ng-format-${format}`)}>
                <SeafoodPlatterAdvices guestsNumber={sliderValues[0]} />
                {action && (
                    <Actions justify="center">
                        <Button text={action.text} url={action.url} />
                    </Actions>
                )}
            </Stack>
        </Suite>
    );
}
