import {MutableRefObject, useEffect, useState} from "react";

type UseIntersection = {
    element: MutableRefObject<HTMLDivElement | null>;
    rootMargin?: string;
    threshold?: number;
};

export const useIntersection = ({element, rootMargin = "0px", threshold = 1}: UseIntersection) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const current = element?.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            },
            {rootMargin, threshold},
        );
        current && observer?.observe(current);

        return () => {
            current && observer.unobserve(current);
        };
    }, []);

    return isVisible;
};
