import * as zod from "zod";

import {fieldErrors} from "./helpers/fieldErrors";

export const basicContactFormSchema = zod.object({
    firstName: zod.string().trim().min(1, {message: fieldErrors["required"]}),
    lastName: zod.string().trim().min(1, {message: fieldErrors["required"]}),
    email: zod.string().trim().min(1, {message: fieldErrors["required"]}).email({message: fieldErrors["email"]}),
    formReference: zod.string(),
    formTracker: zod.string(),
});

export type BasicContactFormSchema = zod.infer<typeof basicContactFormSchema>;

export const newsletterFormSchema = zod.object({
    email: zod.string().trim().min(1, {message: fieldErrors["required"]}).email({message: fieldErrors["email"]}),
    coupon: zod.string(),
    formReference: zod.string(),
    formTracker: zod.string(),
    newsletterSource: zod.string(),
});

export type NewsletterFormSchema = zod.infer<typeof newsletterFormSchema>;
