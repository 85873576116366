"use client";

import React, {useEffect, useRef, useState} from "react";
import ReactPlayer from "react-player";
import {merge} from "lodash";

import {useIntersection} from "./useIntersection";

const standardConfiguration = {
    controls: true,
    width: "100%",
    height: "100%",
};

const autoPlayConfiguration = {
    loop: true,
    muted: true,
    playing: true,
};

type VideoPlayer = {
    url: string;
    withAutoPlay?: boolean;
};

export default function VideoPlayer({url, withAutoPlay = false}: VideoPlayer) {
    const [Player, setPlayer] = useState<JSX.Element>();
    const triggerRef = useRef<HTMLDivElement | null>(null);
    const isVisible = useIntersection({element: triggerRef});

    useEffect(() => {
        const configuration =
            withAutoPlay && isVisible ? merge(standardConfiguration, autoPlayConfiguration) : standardConfiguration;
        setPlayer(<ReactPlayer url={url} {...configuration} />);
    }, [url, withAutoPlay, isVisible]);

    return (
        <div className="ng-video-player-wrapper" ref={triggerRef}>
            {Player}
        </div>
    );
}
