"use client";

import React, {useEffect, useState} from "react";
import {faShareNodes as shareIcon} from "@fortawesome/free-solid-svg-icons";

import Button from "../actions/Button";

async function getFileFromUrl(url: string, name: string, defaultType = "image/webp") {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
        type: data.type || defaultType,
    });
}

type SocialShareButtonProps = {
    buttonText?: string;
    shareData: {
        description: string;
        imageUrl?: string;
        title: string;
    };
};

export default function SocialShareButton({buttonText = "Partager", shareData}: SocialShareButtonProps) {
    const {description, imageUrl, title} = shareData || {};
    const [share, setShare] = useState<any>();

    useEffect(() => {
        if (!navigator.share) return;

        setShare(() => async () => {
            try {
                const shareOptions: ShareData = {title, text: description, url: window.location.href};
                if (imageUrl) {
                    const imageName = imageUrl.split("/").at(-1);
                    const shareImage = imageName && (await getFileFromUrl(imageUrl, imageName));
                    if (shareImage && navigator.canShare({files: [shareImage]})) shareOptions.files = [shareImage];
                }
                await navigator.share(shareOptions);
            } catch {
                /* do nothing */
            }
        });
    }, []);

    if (!share) return null;

    return <Button onClick={share} icon={shareIcon} text={buttonText} />;
}
