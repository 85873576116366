import React, {PropsWithChildren, ReactNode} from "react";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight as nextIcon, faChevronLeft as previousIcon} from "@fortawesome/free-solid-svg-icons";

type PropType = PropsWithChildren<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>;

export const CarouselButtonPrevious: React.FC<PropType> = (props) => {
    const {children, ...restProps} = props;

    return (
        <button className="ng-carousel-button ng-previous" type="button" {...restProps}>
            <FontAwesomeIcon icon={previousIcon} fixedWidth />
            {children}
        </button>
    );
};

export const CarouselButtonNext: React.FC<PropType> = (props) => {
    const {children, ...restProps} = props;

    return (
        <button className="ng-carousel-button ng-next" type="button" {...restProps}>
            <FontAwesomeIcon icon={nextIcon} fixedWidth />
            {children}
        </button>
    );
};

type CarouselButtonDotProps = {
    children?: ReactNode;
    onClick: () => void;
    selected?: boolean;
};

export const CarouselDot = ({children, onClick, selected}: CarouselButtonDotProps) => {
    return (
        <button className={classNames("ng-carousel-dot", {"ng-selected": selected})} onClick={onClick} type="button">
            {children}
        </button>
    );
};
