import React, {ReactNode} from "react";
import classes from "classnames";

import Title from "@ng-components/primitives/Title";

interface BlockTitleProps {
    children?: ReactNode;
    className?: string;
    color?: "black" | "white";
    justify: "left" | "center";
    justifyMobile?: "left" | "center";
    paddingBottom?: "medium" | "none";
}

export default function BlockTitle({
    className,
    children,
    color = "black",
    justify,
    justifyMobile,
    paddingBottom = "none",
}: BlockTitleProps) {
    if (!children) return null;

    return (
        <Title
            className={classes("ng-block-title", `ng-padding-bottom-${paddingBottom}`, className)}
            color={color}
            level={2}
            justify={justify}
            justifyMobile={justifyMobile}
            size="large"
        >
            {children}
        </Title>
    );
}
