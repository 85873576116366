import React, {ReactNode} from "react";

import Actions from "../actions/Actions";
import Button from "../actions/Button";
import Stack from "../primitives/Stack";

type FormProps = {
    children: ReactNode;
    className?: string;
    isSubmitting?: boolean;
    onSubmit: () => void;
    submitButtonFullWidth?: boolean;
    submitButtonJustify?: "center" | "left";
    submitButtonText: string;
};

export default function Form({
    children,
    className,
    isSubmitting = false,
    onSubmit,
    submitButtonFullWidth,
    submitButtonJustify = "center",
    submitButtonText,
}: FormProps) {
    return (
        <form className={className} onSubmit={onSubmit}>
            <Stack>
                {children}
                <Actions justify={submitButtonJustify}>
                    <Button
                        fullWidth={submitButtonFullWidth}
                        onClick={() => undefined}
                        loading={isSubmitting}
                        text={submitButtonText}
                        type="submit"
                    />
                </Actions>
            </Stack>
        </form>
    );
}
