import React from "react";
import Image from "next/image";

import iconFish from "@app/styles/images/seafood-platter/fish.svg";
import iconShell from "@app/styles/images/seafood-platter/shell.svg";
import iconCrustacean from "@app/styles/images/seafood-platter/crustacean.svg";
import iconOyster from "@app/styles/images/seafood-platter/oyster.svg";

import _ from "@business/components/common/Reference";
import Paragraph from "../primitives/Paragraph";
import Stack from "../primitives/Stack";
import Suite from "../primitives/Suite";
import Title from "../primitives/Title";

type SeafoodType = "crustacean" | "oyster" | "shell" | "smokedFish";

const seafoodTypes = {
    crustacean: {
        icon: iconCrustacean,
        title: "Crustacés",
        quantityPerGuest: 800,
        unit: "gram",
    },
    oyster: {
        icon: iconOyster,
        title: "Huîtres",
        quantityPerGuest: 6,
        unit: "pieceAsDozen",
    },
    shell: {
        icon: iconShell,
        title: "Coquillages",
        quantityPerGuest: 300,
        unit: "gram",
    },
    smokedFish: {
        icon: iconFish,
        title: "Poisson fumé",
        quantityPerGuest: 100,
        unit: "gram",
    },
};

type GetGuestQuantityProps = {
    guestsNumber: number;
    seafoodType: SeafoodType;
};

export function getTotalQuantity({guestsNumber, seafoodType}: GetGuestQuantityProps): string | undefined {
    const {quantityPerGuest, unit} = seafoodTypes[seafoodType];
    let quantity = quantityPerGuest * guestsNumber;
    if (seafoodType === "oyster") quantity = Math.ceil(quantity / 12) * 12;
    return _.getFormattedQuantityUnitAndValue({unit, quantity});
}

type SeafoodPlatterAdviceProps = {
    guestsNumber: number;
    seafoodType: SeafoodType;
};

export default function SeafoodPlatterAdvice({guestsNumber, seafoodType}: SeafoodPlatterAdviceProps) {
    const {icon, title} = seafoodTypes[seafoodType];
    const formattedTotalQuantity = getTotalQuantity({seafoodType, guestsNumber});

    if (!formattedTotalQuantity) return null;

    return (
        <Suite className="ng-seefood-platter-advice" spacing="extra-small" wrap="never">
            <Image src={icon} alt={title} />
            <Stack gap="none">
                <Title level={4} size="extra-small" whiteSpace="nowrap">
                    {title}
                </Title>
                <Paragraph whiteSpace="nowrap">{formattedTotalQuantity}</Paragraph>
            </Stack>
        </Suite>
    );
}
