"use client";
import React from "react";
import * as RadixSlider from "@radix-ui/react-slider";

const Slider = (props: RadixSlider.SliderProps) => {
    const value = props.value || props.defaultValue || [];

    return (
        <RadixSlider.Slider className="ng-slider-root" {...props}>
            <RadixSlider.Track className="ng-slider-track">
                <RadixSlider.Range className="ng-slider-range" />
            </RadixSlider.Track>
            {value.map((_, i) => (
                <RadixSlider.Thumb key={i} className="ng-slider-thumb" aria-label="Volume" />
            ))}
        </RadixSlider.Slider>
    );
};
// });

export default Slider;
