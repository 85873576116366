import {tracker} from "@business/components/common/Tracker";

type SubmitHubSpotFormProps = {
    body: string;
    formReference: "newsletter" | "basic-contact";
    success?: string;
};

export async function submitHubSpotForm<TResponse>({body, formReference}: SubmitHubSpotFormProps): Promise<TResponse> {
    const url = `/shop-api/hubspot/forms/${formReference}`;
    const options = {
        body,
        headers: {"Content-Type": "application/json"},
        method: "post",
    };

    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            const {url, status, statusText} = response;
            const body = await response.text();
            throw new Error("HubSpot form submission failed", {cause: {url, status, statusText, body}});
        }
        const data = await response.json();
        return data as TResponse;
    } catch (error) {
        tracker.error(error as Error);
        throw new Error("HubSpot form submission failed", {cause: {url, body}});
    }
}
