"use client";
import React, {ReactNode, useCallback, useEffect, useState} from "react";
import useEmblaCarousel from "embla-carousel-react";
import {EmblaCarouselType, EmblaOptionsType} from "embla-carousel";
import {CarouselDot, CarouselButtonNext, CarouselButtonPrevious} from "./CarouselButtons";
import classNames from "classnames";

type CarouselProps = {
    columnsCount?: 4 | 6;
    options?: EmblaOptionsType;
    overflow?: "visible" | "hidden";
    overflowShadowColor?: "blue" | "grey" | "turquoise" | "white" | "yellow";
    slides: ReactNode[];
    withDotsNavigation?: boolean;
    withPreviousNextNavigation?: boolean;
};

export default function Carousel({
    columnsCount = 4,
    options,
    overflow = "visible",
    overflowShadowColor = "white",
    slides,
    withDotsNavigation = false,
    withPreviousNextNavigation = true,
}: CarouselProps) {
    const [emblaRef, emblaApi] = useEmblaCarousel({align: "start", dragFree: true, ...options});
    const [buttonPreviousDisabled, setButtonPreviousDisabled] = useState(true);
    const [buttonNextDisabled, setButtonNextDisabled] = useState(true);
    const [grabCursor, setGrabCursor] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

    const scrollPrevious = useCallback(() => {
        emblaApi && emblaApi.scrollPrev();
    }, [emblaApi]);

    const scrollNext = useCallback(() => {
        emblaApi && emblaApi.scrollNext();
    }, [emblaApi]);

    const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

    const onInit = useCallback((emblaApi: EmblaCarouselType) => {
        setScrollSnaps(emblaApi.scrollSnapList());
    }, []);

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
        setButtonPreviousDisabled(!emblaApi.canScrollPrev());
        setButtonNextDisabled(!emblaApi.canScrollNext());
        setGrabCursor(emblaApi.canScrollPrev() || emblaApi.canScrollNext());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        onInit(emblaApi);
        onSelect(emblaApi);
        emblaApi.on("reInit", onInit);
        emblaApi.on("reInit", onSelect);
        emblaApi.on("select", onSelect);
    }, [emblaApi, onInit, onSelect]);

    return (
        <>
            <div
                className={classNames(
                    "ng-carousel",
                    `ng-overflow-${overflow}`,
                    `ng-shadow-color-${overflowShadowColor}`,
                )}
            >
                <div className={classNames("ng-carousel-viewport", `ng-overflow-${overflow}`)} ref={emblaRef}>
                    <div
                        className={classNames("ng-carousel-container", `ng-columns-${columnsCount}`, {
                            "ng-grab-cursor": grabCursor,
                        })}
                    >
                        {slides.map((slide, index) => (
                            <div className="ng-carousel-slide" key={index}>
                                {slide}
                            </div>
                        ))}
                    </div>
                </div>

                {withPreviousNextNavigation && (
                    <>
                        <CarouselButtonPrevious onClick={scrollPrevious} disabled={buttonPreviousDisabled} />
                        <CarouselButtonNext onClick={scrollNext} disabled={buttonNextDisabled} />
                    </>
                )}
            </div>

            {withDotsNavigation && (
                <div className="ng-carousel-dots">
                    {scrollSnaps.map((_, index) => (
                        <CarouselDot key={index} onClick={() => scrollTo(index)} selected={index === selectedIndex} />
                    ))}
                </div>
            )}
        </>
    );
}
